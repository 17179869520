import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import ContactFormHome from "../components/contact-form-home";
import Layout from "../components/layout";
import CartContext from "../context/CartContext";
import { Button, Card } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

const ResourcesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpTag(filter: { count: { ne: 0 } }) {
				nodes {
					name
					slug
				}
			}
			allWpPost(
				sort: { fields: dateGmt, order: DESC }
				filter: { title: { ne: "Website Images" } }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt
					blogFields {
						minsToRead
						featuredImage {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							altText
						}
					}
					tags {
						nodes {
							name
							slug
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const tags = data.allWpTag.nodes;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Resources",
				item: {
					url: `${siteUrl}/resources`,
					id: `${siteUrl}/resources`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Resources | Flex Office Solutions"
				description="Read our latest posts."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/resources`,
					title: "Resources | Flex Office Solutions",
					description: "Read our latest posts.",
					images: [
						{
							url: `${data.heroInterior?.localFile.publicURL}`,
							width: `${data.heroInterior?.localFile.childImageSharp.original.width}`,
							height: `${data.heroInterior?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroInterior?.altText}`,
						},
					],
				}}
			/>
			<div style={{ overflow: "hidden" }}>
				<section className="bg-primary bg-opacity-10 py-4 py-lg-5 mb-xl-3 mb-2">
					<Container>
						<Row>
							<Col className="text-center">
								<p className="text-med-grey fw-bold">OUR LATEST POSTS</p>
								<h1>Resources</h1>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="py-2 py-lg-3">
					<Container>
						<Row className="g-xl-6 g-4 h-100 ">
							<div className="d-flex align-items-center">
								<ul className="list-unstyled text-center">
									{tags.map((tag) => (
										<Link to={`/resources/tags/${tag.slug}`}>
											<li
												key={tag.id}
												className="d-inline-block py-1 px-3 me-3 mb-2 fw-light border border-1 border-med-grey text-med-grey rounded-pill"
											>
												{tag.name}
											</li>
										</Link>
									))}
								</ul>
							</div>
						</Row>
					</Container>
				</section>
				<section className="pt-2 pt-lg-3 pb-5">
					<Container>
						<Row className="g-xl-6 g-4 h-100 ">
							{data.allWpPost.nodes.map((post) => (
								<Col
									style={{ minHeight: "100%", borderRadius: "10px" }}
									className=""
									md={6}
									lg={4}
								>
									<Card className="h-100 position-relative">
										<Card.Img
											as={GatsbyImage}
											style={{ height: "250px" }}
											variant="top"
											image={
												post?.blogFields?.featuredImage?.localFile
													?.childImageSharp?.gatsbyImageData
											}
											alt={post.blogFields.featuredImage.altText}
										/>
										<Card.Body className="p-3 pb-6">
											{post.blogFields?.minsToRead && (
												<p className="pt-0 my-0 text-dark-blue pb-2 d-md-none fw-bold">
													{post.blogFields.minsToRead} min read
												</p>
											)}
											<Card.Title className="text-primary">
												{post.title}
											</Card.Title>
											<Card.Text>{parse(post.excerpt)}</Card.Text>

											<div className="position-absolute align-items-end d-flex justify-content-between w-100 bottom-0 start-0 p-3">
												<Button
													as={Link}
													to={`/resources/${post.slug}`}
													className="px-3  w-100 w-md-auto text-white mt-1"
													variant="primary"
												>
													{" "}
													Read More
												</Button>
												{post.blogFields?.minsToRead && (
													<p className="py-0 my-0 text-primary d-none d-md-inline-block fw-bold">
														{post.blogFields.minsToRead} min read
													</p>
												)}
											</div>
										</Card.Body>
									</Card>
								</Col>
							))}
						</Row>
					</Container>
				</section>
			</div>
		</Layout>
	);
};

export default ResourcesPage;
